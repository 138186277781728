<template>
  <div>
    <!-- /.modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit.prevent="save" class="card">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5 class="modal-title" id="exampleModalLabel">
                <i v-bind:class="form.id ? 'fa fa-pen' : 'fa fa-plus'"></i>
                {{ form.id ? "Ubah Karyawan" : "Tambah Karyawan" }}
              </h5>
            </div>
            <div class="modal-body">
              <div class="row" style="margin-left: 10px; margin-right: 10px">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="recipient-name" class="col-form-label"
                      >Nama Karyawan<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="recipient-name"
                      v-model="form.employee_name"
                      :disabled="loading || isDetail"
                      autocomplete="off"
                      @blur="validate('employee_name')"
                      @keypress="validate('employee_name')"
                    />
                    <span v-if="!!error.employee_name" class="text-red">{{
                      error.employee_name
                    }}</span>
                    <!-- <span v-if="employeeNameExist" class="text-red">{{
                      error.employee_name_exist
                    }}</span> -->
                  </div>

                  <div class="form-group">
                    <label for="recipient-name" class="col-form-label"
                      >ID Karyawan<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="recipient-name"
                      v-model="form.employee_id"
                      autocomplete="off"
                      @blur="validate('employee_id')"
                      @keypress="validate('employee_id')"
                    />
                    <span v-if="!!error.employee_id" class="text-red">{{
                      error.employee_id
                    }}</span>
                    <!-- <span v-if="employeeIdExist" class="text-red">{{
                      error.employee_id_exist
                    }}</span> -->
                  </div>

                  <div class="form-group">
                    <label for="message-text" class="col-form-label"
                      >RFID<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="recipient-rfid"
                      v-model="form.rfid"
                      :disabled="loading || isDetail"
                      autocomplete="off"
                      @blur="validate('rfid')"
                      @keypress="validate('rfid')"
                    />
                    <span v-if="!!error.rfid" class="text-red">{{
                      error.rfid
                    }}</span>
                  </div>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label"
                      >Jabatan<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="recipient-department"
                      v-model="form.department"
                      :disabled="loading || isDetail"
                      autocomplete="off"
                      @blur="validate('department')"
                      @keypress="validate('department')"
                    />
                    <span v-if="!!error.department" class="text-red">{{
                      error.department
                    }}</span>
                  </div>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label"
                      >Status<span class="text-red">*</span></label
                    >
                    <div class="row card-switch">
                      <label class="switch">
                        <input
                          type="checkbox"
                          @click="toggleCheckbox"
                          v-model="form.status"
                        />
                        <div class="slider round"></div>
                      </label>
                      <div class="col">
                        {{ form.status === true ? "Aktif" : "Tidak Aktif" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Batal
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="loading"
                >
                  <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                  {{
                    loading && form.id
                      ? "Simpan ..."
                      : form.id
                      ? "Simpan"
                      : loading
                      ? "Tambah ..."
                      : "Tambah"
                  }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- header Informasi karyawan -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6" style="padding-left: 26px !important">
            <h1 class="m-0 text-dark" style="font-size: 2em">
              Informasi Karyawan
            </h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <!-- <li>
                  <button
                    type="button"
                    class="btn btn-block btn-outline-primary btn-lg"
                  >
                    <span class="fas fa-upload mr-2"></span>
                    Export
                  </button>
                </li> -->
              <li class="ml-3">
                <button
                  type="button"
                  class="btn btn-block btn-primary btn-lg"
                  @click="showModal"
                >
                  <span class="fas fa-plus mr-2"></span>Tambah Karyawan
                </button>
              </li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- end of header -->

    <!-- table for Informasi Karyawan -->
    <div class="table-wrapper" style="position: relative">
      <div
        v-if="dataEmployee?.length > 0"
        class="row mb-2"
        style="
          margin-left: 0;
          margin-right: 0;
          position: absolute;
          left: 21px;
          top: 13px;
          width: 77%;
          z-index: 1;
        "
      >
        <div class="d-flex align-items-center">
          <div class="mr-2" style="font-size: 16px">Filter :</div>
          <div class="form-group mb-0">
            <!-- <label for="recipient-status" class="col-form-label">Status</label> -->
            <select
              class="form-control select-status"
              v-model="status"
              @change="filterEmployee"
            >
              <option disabled>Status</option>
              <option value="">Semua Status</option>
              <option value="true">Aktif</option>
              <option value="false">Tidak Aktif</option>
            </select>
          </div>
        </div>
        <!-- <div class="col-sm-2">
          <div class="form-group">
            <label for="recipient-status" class="col-form-label">Status</label>
            <select
              class="form-control select-status"
              v-model="status_access"
              @change="filterEmployee"
            >
              <option disabled>Akses</option>
              <option value="">Semua Akses</option>
              <option value="Pemohon">Pemohon</option>
              <option value="Penyetuju">Penyetuju</option>
            </select>
          </div>
        </div> -->
        <!-- /.col -->
      </div>
      <div v-if="dataEmployee?.length > 0">
        <data-tables-master-data
          :config="config"
          class="container-fluid"
          id="sampleTable"
        ></data-tables-master-data>
      </div>
      <div v-else class="image-empty">
        <img src="../../assets/img/emptyEmployee.png" alt="empty-employee" />
      </div>
    </div>
    <!-- end of table -->
    <ModalConfirmation ref="refModalConfirmation" />
  </div>
</template>

<script>
const { console } = window;
import DataTablesMasterData from "@components/DataTablesMasterData.vue";
import { boolean, object, string } from "yup";
import ModalConfirmation from "@components/modal/Confirmation.vue";

const employeeFormSchema = object().shape({
  employee_name: string().required("Nama Karyawan wajib diisi."),
  employee_id: string().required("ID Karyawan wajib diisi."),
  rfid: string().required("RFID wajib diisi."),
  department: string().required("Jabatan wajib diisi."),
  status: boolean(),
});

export default {
  components: {
    DataTablesMasterData,
    ModalConfirmation,
  },

  data() {
    return {
      active: false,
      errorDialog: null,
      errorText: "",
      uploadFieldName: "file",
      error: {
        employee_name: "",
        employee_id: "",
        rfid: "",
        department: "",
      },
      loading: false,
      text_validation: null,
      maxSize: 1024,
      status: "",
      status_access: "",
      employeeNameExist: false,
      employeeIdExist: false,
      form: {
        id: null,
        employee_name: "",
        employee_id: "",
        employee_id_exist: "",
        rfid: "",
        rfid_exist: "",
        department: "",
        status: true,
        // description: "",
      },
      disabled: false,
      isDetail: false,
      dataEmployee: null,
      config: {
        columns: [
          {
            title: "Nama Karyawan",
            data: "employee_name",
          },
          {
            title: "ID Karyawan",
            data: "employee_id",
          },
          {
            title: "RFID",
            data: "rfid",
          },
          {
            title: "Jabatan",
            data: "department",
          },
          {
            title: "Status",
            data: "status",
            searchable: false,
            render: (data) => {
              return data === true ? "Aktif" : "Tidak Aktif";
            },
          },
          {
            title: "Aksi",
            data: "id",
            render: (val, _, row) => {
              return `
                    <div> 
                        <a 
                            data-id="${val}" 
                            class="editor-edit" 
                            data-action="edit"
                            style="color:#9E9E9E;margin-right:10px;"
                        >
                            <i class="fa fa-edit"></i>
                        </a>
                    </div>`;
            },
          },
        ],
        drawCallback: (settings) => {
          //for click edit
          $(".editor-edit").on("click", (e) => {
            this.getEdit($(e.target).closest("a").data("id"));
          });
          // $(".delete").on("click", (e) => {
          //   this.onDelete($(e.target).closest("a").data("row"));
          // });
        },
        url: this.Api.base + "/employee",
        order: [[0, "desc"]],
        filter: true,
      },
    };
  },
  mounted() {
    this.getDataEmployee();
  },
  props: {
    value: Object,
  },
  methods: {
    validate(field) {
      employeeFormSchema
        .validateAt(field, this.form)
        .then((res) => {
          this.error[field] = "";
        })
        .catch((err) => {
          this.error[field] = err.message;
        });
    },
    getEdit(id) {
      if (id) {
        this.Api.get(`/employee/${id}`)
          .then((res) => {
            if (res.data) {
              $("#exampleModal").modal("show");
              this.error = {};
              this.form.id = res.data.id;
              this.form.employee_name = res.data.employee_name;
              this.form.employee_id = res.data.employee_id;
              this.form.employee_id_exist = res.data.employee_id;
              this.form.rfid_exist = res.data.rfid;
              this.form.rfid = res.data.rfid;
              this.form.department = res.data.department;
              this.form.status = res.data.status;
              this.isDetail = false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    onDelete(val) {
      this.$refs.refModalConfirmation.show({
        title: "Hapus Informasi Karyawan?",
        subTitle: `Apakah anda yakin untuk menhapus informasi karyawan ‘${val.employee_name}’?`,
        type: "warning",
      });
    },
    showModal() {
      this.form.id = null;
      this.form.employee_name = "";
      this.form.employee_id = "";
      this.form.rfid = "";
      this.form.department = "";
      this.form.status = true;
      this.error = {};
      $("#exampleModal").modal("show");
      this.loading = false;
    },
    toggleCheckbox() {
      this.form.status = !this.form.status;
      this.$emit("setCheckboxVal", this.form.status);
    },
    handleCheckboxChange(event) {
      const { value, checked } = event.target;
      if (checked) {
        this.selectedAccess.push(value);
      } else {
        this.selectedAccess = this.selectedRivers.filter(
          (acc) => acc !== value
        );
      }
    },
    async processSave() {
      try {
        if (this.form.id != null) {
          await this.Api.put(`/employee/${this.form.id}`, this.form);
          // await this.Api.put(`/employee/${this.form.id}/status`, this.form);
          this.$toast.success("Data berhasil diubah.", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else {
          await this.Api.post("/employee", this.form);
          this.$toast.success("Data berhasil ditambah.", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        this.loading = false;
        this.refresh();
        $("#exampleModal").modal("hide");
        this.error = {};
      } catch (error) {
        this.loading = false;
        this.$toast.error(error.response.data.message, {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async save() {
      this.loading = true;

      const findRfid = await this.Api.get(
        `/employee?filters=[["rfid","=", "${this.form.rfid}"]]`
      );
      const rfidNotSame = this.form.id
        ? findRfid.data.content.length === 0 ||
          this.form.rfid === this.form.rfid_exist
        : findRfid.data.content.length === 0;

      const findEmployeeId = await this.Api.get(
        `/employee?filters=[["employee_id","=", "${this.form.employee_id}"]]`
      );

      const employeeIdNotSame = this.form.id
        ? findEmployeeId.data.content.length === 0 ||
          this.form.employee_id === this.form.employee_id_exist
        : findEmployeeId.data.content.length === 0;

      employeeFormSchema
        .validate(this.form, { abortEarly: false })
        .then(() => {
          this.error = {};
          if (!rfidNotSame) {
            this.loading = false;
            this.error.rfid = "RFID sudah tersedia.";
          }

          if (!employeeIdNotSame) {
            this.loading = false;
            this.error.employee_id = "ID Karyawan sudah tersedia.";
          }

          if (rfidNotSame && employeeIdNotSame) {
            this.processSave();
          }
        })
        .catch((err) => {
          this.loading = false;
          err.inner.forEach((error) => {
            this.error[error.path] = error.message;
          });
        });
    },
    //filter employee to get table config
    filterEmployee() {
      let isRequest = this.status_access === "Pemohon" ? true : false;
      let isApprove = this.status_access === "Penyetuju" ? true : false;

      let filter =
        this.status && this.status_access
          ? '[["is_requester","=",' +
            isRequest +
            '],["OR"],["is_approver","=",' +
            isApprove +
            '],["AND"],["status","=", ' +
            this.status +
            "]]"
          : this.status_access
          ? '[["is_requester","=",' +
            isRequest +
            '],["OR"],["is_approver","=",' +
            isApprove +
            "]]"
          : '[["status","=", ' + this.status + "]]";

      this.config = {
        columns: this.config.columns,
        drawCallback: this.config.drawCallback,
        url: this.Api.base + "/employee?filters=" + filter,
        order: [[0, "desc"]],
      };
    },
    refresh() {
      this.config = {
        url: this.Api.base + "/employee",
      };
    },
    async getDataEmployee() {
      const res = await Api.get("/employee");
      this.dataEmployee = res.data.content;
    },
  },
};
</script>
